<template>
  <div>
    <window-header></window-header>
    <nav-form :onlyIcons="true"></nav-form>
    <section>
      <article style="height: 95px; width:411px">
        <field
          name="codigo"
          widget="input"
          label="Código"
          placeholder="Cód"
		  help="Cód. del Almacén"
          width="50px"
          required
          inputStyle="font-weight:bold; text-align:center;"
          style="top:9px; left:30px;"
          searchable
          :readonly="mode=='edit'"
        />
        <field
          name="nombre"
          widget="input"
          searchable
          label="Almacén"
          help="Descripción del Almacén"
          placeholder="Descripción del Almacén"
          width="266px"
          style="top:9px; left:95px;"
        />
        <field
          name="delegacion_id"
          dbAdapter="delegacion"
          widget="m2o"
          searchable
          label="Delegación"
          inline-label
          help="Delegación a la que pertenece el Almacén"
          placeholder="Delegación"
          width="268px"
          style="top:50px; left:32px;"
        />
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        <div class="item-title">Listado</div>
        <div class="item-after">{{count}}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function() {
    return {
      title: "Almacenes",
      dbAdapter: "almacen",
      primary: "codigo",
      sequence: { name: "almacen" }
    };
  }
};
</script>
